import React, { useState, useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { useI18next, I18nextContext } from "gatsby-plugin-react-i18next"
import { CSSTransition, TransitionGroup } from "react-transition-group"

import { MyContext } from "../context/my-context"
import { upsert, remove } from "../context/records-reducer"
import { compressToEncodedURIComponent } from "lz-string"

import { baseUrl, printserviceUrl } from "../settings"

import { scrollToTarget, getLangUrlPrefix } from "../util"
import { getSelectOptions, getCustomLSDataFromExistingLS, getEmptyCustomLU, updateCountsArray, sortArrayOfObjectsBy } from "../util/data"

import Select from "react-select"

import Seo from "../parts/seo"
import Layout from "../parts/layout"
import Row from "../parts/row"
import WwwwInfo from "../parts/wwwwInfo"
import HeadingUnderline from "../parts/headingUnderline"
import CustomLs from "../parts/customLs"

import Pdf from "../images/inline/icon-pdf.svg"
import Print from "../images/inline/icon-print.svg"
import Share from "../images/inline/icon-share.svg"
import Export from "../images/inline/icon-export.svg"
import { nanoid } from "nanoid"
import { getHashLS } from "../util/data"

import { useUnmount } from "react-use";

const getInitialData = (id, customLearningScenarios, learningScenarios, learningUnits, passedState, t) => {
  const customLS = customLearningScenarios?.find(ls => ls.id === id)
  if(customLS) {
    return customLS
  }

  const lsToClone = learningScenarios.find(ls => ls.id === passedState?.cloneFrom)
  if(lsToClone) {
    return getCustomLSDataFromExistingLS(id, lsToClone, learningUnits, t)
  }

  const initialData = passedState?.initialData || {}

  return {
    id: id,
    buildMode: "from scratch",
    created: (new Date()).toISOString(),
    baseId: nanoid(),
    lu: [],
    ...initialData
  }

}


const CustomLsEditor = ({pageContext, location}) => {
  const { t, navigate } = useI18next()
  const i18n = useContext(I18nextContext)

  const defaultSelectProps = { blurInputOnSelect: true, isSearchable: false, className: "select-wrapper", classNamePrefix: "select", placeholder: t("Select...") }

  const learningScenarios = pageContext.learningScenarios.allNodeLearningScenario.nodes
  const learningUnits = pageContext.learningUnits.allNodeLearningUnit.nodes

  const phaseOptions = getSelectOptions(pageContext.terms.allTaxonomyTermPhase.nodes)
  const formatOptions = getSelectOptions(pageContext.terms.allTaxonomyTermLuFormat.nodes)

  const whatOptions = getSelectOptions(pageContext.terms.allTaxonomyTermSteamSubjects.nodes)
  const whoOptions = getSelectOptions(pageContext.terms.allTaxonomyTermRoles.nodes)
  const whereOptions = getSelectOptions(pageContext.terms.allTaxonomyTermLocations.nodes)
  const withOptions = getSelectOptions(pageContext.terms.allTaxonomyTermEngagement.nodes)
  const { state, dispatch } = useContext(MyContext)

  // get id sent from overview
  let id = location?.state?.hiddenid //only internal

  // if we got here directly from url open latest if
  if(!id && typeof window !== "undefined") {

    let latest = (state?.ls || []).sort( (a, b) => {
      return ( new Date(a.time) < new Date(b.time) )
    })[0]

    if(latest) {
      id = latest.id
    } else {
      //nothing to edit first create
      navigate("/build-your-own")
    }
  }

  // load the LS
  const [data, setData] = useState(getInitialData(id, state?.ls, learningScenarios, learningUnits, location.state, t))

  //whowhatwherewith
  const subjectsWithCount = []
  const partnersWithCount = []
  const locationsWithCount = []
  const engagementsWithCount = []
  data.lu.forEach(lu => {
    lu.steam_subjects.forEach(item => { updateCountsArray(subjectsWithCount, (whatOptions?.find(o => o.value === item) || {}).label)})
    lu.partners.forEach(item => { updateCountsArray(partnersWithCount, (whoOptions?.find(o => o.value === item) || {}).label) })
    lu.location.forEach(item => { updateCountsArray(locationsWithCount, (whereOptions?.find(o => o.value === item) || {}).label) })
    lu.engagement.forEach(item => { updateCountsArray(engagementsWithCount, (withOptions?.find(o => o.value === item) || {}).label) })
  })
  sortArrayOfObjectsBy(subjectsWithCount, "count")
  sortArrayOfObjectsBy(partnersWithCount, "count")
  sortArrayOfObjectsBy(locationsWithCount, "count")
  sortArrayOfObjectsBy(engagementsWithCount, "count")

  const storeIfChanged = (editor) => {
    // check if data really changed because we want to keep mod date if we don't modify
    let stored = (state?.ls || []).find( (ls) => ls.id === id)
    if(getHashLS(stored) !== getHashLS(editor)) {
      // generate baseId for older versions that don't have one yet
      if(!editor.baseId) {
        editor.baseId = nanoid()
      }
      // update time
      let time = (new Date()).toISOString()
      //console.log("storing: ", data.id)
      upsert(dispatch.ls, {
        ...data,
        time: time //date modified (named time from first version)
      })
      // remove the "new" status
      remove(dispatch.imports, editor.id)
    }
  }

  // gets called when data changes
  useEffect(() => {
    if(data) {
      // store data when user inactive for 1 second
      const timeOutId = setTimeout(() => {
        storeIfChanged(data)
      }, 2000)
      return () => clearTimeout(timeOutId)
    }
  }, [data, dispatch?.ls])

  // store at unmount
  useUnmount(() => {
    // do not save if we get here without any id to being edited
    if(id) {
      storeIfChanged(data)
    }
  });

  const setLskey = (key, value) => {
    const newData = { ...data }
    newData[key] = value
    setData(newData)
  }

  const setLukey = (key, value, i) => {
    let newData = {
      ...data,
      lu: data.lu.map( (k,i) => { return {...k} } )
    }
    newData.lu[i][key] = value
    setData(newData)
  }


  const addLu = (lu, position=data.lu.length) => {
    const newData = { ...data }

    newData.lu.splice(position, 0, lu)

    setData(newData)

    setTimeout(() => {
      scrollToTarget(lu.id, 100)
    }, 10)
  }

  const deleteLu = (i) => {
    const newData = { ...data }

    newData.lu.splice(i, 1)

    setData(newData)
  }

  const moveLu = (oldPosition, newPosition) => {
    const luToMove = data.lu[oldPosition]

    deleteLu(oldPosition)

    setTimeout(() => {
      addLu(luToMove, newPosition)
    }, 150)
  }


  const handleLuAddClick = () => {
    addLu(getEmptyCustomLU())
  }

  const handleLuUpClick = (i) => {
    moveLu(i, i-1)
  }

  const handleLuDownClick = (i) => {
    moveLu(i, i+1)
  }

  const handleLuDeleteClick = (i) => {
    dispatch.dialog({
      title: t("BUILD-DELETE_CONFIRMATION-TITLE"),
      body: <p>{ t("BUILD-DELETE_CONFIRMATION-BODY") } { data.lu[i].title && <strong>"{ data.lu[i].title }"</strong> }?</p>,
      buttons: [
        <button key={"no"} className="button" onClick={ () => dispatch.dialog(null) }>{ t("BUILD-DELETE_CONFIRMATION-NO") }</button>,
        <button key={"yes"} className="button" onClick={ () => { deleteLu(i); dispatch.dialog(null) } }>{ t("BUILD-DELETE_CONFIRMATION-YES") }</button>
      ]
    })
  }

  const getShareUrl = (langPrefix = "en") => {
    const c = compressToEncodedURIComponent(JSON.stringify(data))
    let url = new URL(baseUrl)
    url.pathname = [getLangUrlPrefix(langPrefix), "share", c].filter(e => e).join("/")
    return url.href
  }

  const handleShareClick = () => {
    const url = getShareUrl()

    dispatch.dialog({
      title: t("SHARE-LS-TITLE"),
      body: <>
        <p>{ t("SHARE-LS-BODY") }</p>
        <pre className="share-url">{ url }</pre>
        <p>{ t("SHARE-LS-ALT") }</p>
      </>,
      buttons: [
        <button key={"cancel"} className="button" onClick={ () => dispatch.dialog(null) }>{ t("BUTTONS-CANCEL") }</button>,
        <button key={"copy"} className="button" onClick={ (e) => { navigator.clipboard.writeText(url); e.target.innerText = t("SHARE-COPIED_TO_CLIPBOARD") } }>
          { t("SHARE-COPY_TO_CLIPBOARD") }
        </button>,
      ]
    })
  }

  const handlePdfClick = () => {
    const prefix = i18n.language
    // get share url moet een lang prefix kunnen krijgen
    if(printserviceUrl) {
      console.log("url", getShareUrl(prefix))
      window.open(`${printserviceUrl}/generate?url=${getShareUrl(prefix)}`, '_blank');
    } else {
      dispatch.dialog({
        title: t("PDF-DIALOG_TITLE"),
        body: <>
          <p>{ t("PDF-DIALOG_BODY") }</p>
        </>,
        buttons: [
          <button key={"copy"} className="button">
            <Print />
            { t("BUTTONS-PRINT") }
          </button>
        ]
      })
    }
  }

  // export LS to file on local computer
  const handleExportClick = () => {

    const c = JSON.stringify(data)
    const blob = new Blob([c], {type: 'application/json'});

    dispatch.dialog({
      title: t("EXPORT-DIALOG_TITLE"),
      body: <>
        <p>{ t("EXPORT-DIALOG_EXPLAINER") }</p>
      </>,
      buttons: [
        <a key={"download"} className="button" href={window.URL.createObjectURL(blob)} download={data.title}>
          <Export />
          {t("BUTTONS-EXPORT")}
        </a>
      ]
    })
  }

  const buttons = <Row>
    <div className="ctas center">
    <button className="button" onClick={ () => { handleShareClick() } } >
        <Share /> { t("BUTTONS-SHARE") }
      </button>
      <button className="button" onClick={ () => { handleExportClick() } } >
        <Export /> { t("BUTTONS-EXPORT") }
      </button>
      <button className="button" onClick={ () => { window.print(); return false; }}>
        <Print /> { t("BUTTONS-PRINT") }
      </button>
      <button className="button" onClick={ () => { handlePdfClick() } } >
        <Pdf /> { t("BUTTONS-PDF") }
      </button>
    </div>
</Row>


  // render
  return (
    <Layout currentNavItem={"build-your-own"}>
      { data && <Seo title={ data.title } /> }

      <Row classes={["no-print"]}>
        { data.buildMode === "customise existing" && <>
          <HeadingUnderline title={ t("BUILD-FROM_LS-BUTTON") } classes={["center"]} />
          <p className="introduction">{ t("BUILD-FROM_LS-INTRODUCTION") }</p>
        </> }

        { data.buildMode !== "customise existing" && <>
          <HeadingUnderline title={ t("BUILD-FROM_SCRATCH-BUTTON") } classes={["center"]} />
          <p className="introduction">{ t("BUILD-FROM_SCRATCH-INTRODUCTION") }</p>
        </> }

        <p className="center small">{ t("BUILD-TECH_TIP") }</p>
      </Row>

      { buttons }

      {/* <form onChange={ e => onChange(e) } onSubmit={ e => e.preventDefault() } > */}
      <form onSubmit={ e => e.preventDefault() } className="no-print">
        <Row>
          <div className="editable-fields pane pad-m">
            <input className="editable-input h1 center" name="title" type="text" placeholder="title" value={ data?.title } onChange={ (e) => { setLskey("title", e.target.value) } } />
            <textarea className="editable-input" name="description" placeholder="description" defaultValue={ data?.description } style={ {width:"100%"} } rows="8" onChange={ (e) => { setLskey("description", e.target.value) } } />

            <WwwwInfo subjectsWithCount={ subjectsWithCount } partnersWithCount={ partnersWithCount} locationsWithCount={ locationsWithCount } engagementsWithCount={ engagementsWithCount } luCount={ data.lu.length } />
          </div>
        </Row>

        <Row>
          <h2 className="center">{ t("LEARNING_UNITS") } { data.lu.length > 0 && <span>({data.lu.length})</span> }</h2>
          <TransitionGroup id="ls-learning-units" >
          {/* <div id="ls-learning-units"> */}
            { data.lu.map((item, i) => {
              const phaseSelected = phaseOptions.find(o => o.value === item.phase)
              const formatSelected = formatOptions.filter(term => item.format.indexOf(term.value) !== -1)

              const whatSelected = whatOptions.filter(term => item.steam_subjects.indexOf(term.value) !== -1)
              const whoSelected = whoOptions.filter(term => item.partners.indexOf(term.value) !== -1)
              const whereSelected = whereOptions.filter(term => item.location.indexOf(term.value) !== -1)
              const withSelected = withOptions.filter(term => item.engagement.indexOf(term.value) !== -1)

              return <CSSTransition key={item.id} timeout={150} classNames="transition-item"><div id={item.id} className={`lu-item ${phaseSelected ? `phase-${phaseSelected.value}` : "phase-none"}`}>
                { phaseSelected && <div className="lu-item-phase">{ phaseSelected.label }</div> }
                <div className="pane pad-s flow">
                  <div className="lu-number">{i+1}</div>
                  <input className="editable-input h3" name="title" type="text" placeholder="title" value={ item.title } onChange={ (e) => { setLukey("title", e.target.value, i) } } />
                  <textarea className="editable-input" name="description" placeholder="description" value={ item.description } rows="2" onChange={ (e) => { setLukey("description", e.target.value, i) } } />

                  <div className="grid-columns">
                    <div className="phase">
                      <h4>{ t("SEARCH-PHASE") }</h4>
                      <Select options={ phaseOptions } defaultValue={ phaseSelected } onChange={option => { setLukey("phase",option.value, i) }} {...defaultSelectProps } />
                    </div>
                    <div className="format">
                    <h4>{ t("SEARCH-FORMAT") }</h4>
                      <Select options={ formatOptions } defaultValue={ formatSelected } isMulti onChange={selected => { setLukey("format",selected.map(t => t.value), i) }} {...defaultSelectProps } />
                    </div>
                  </div>

                  <div className="wwww block-margin-m">
                    <div className="what">
                      <h4>{ t("SEARCH-WHAT") }</h4>
                      <Select options={ whatOptions } defaultValue={ whatSelected } isMulti onChange={selected => { setLukey("steam_subjects",selected.map(t => t.value), i) }} {...defaultSelectProps } />
                    </div>
                    <div className="who">
                      <h4>{ t("SEARCH-WHO") }</h4>
                      <Select options={ whoOptions } defaultValue={ whoSelected } isMulti onChange={selected => { setLukey("partners",selected.map(t => t.value), i) }} {...defaultSelectProps } />
                    </div>
                    <div className="where">
                      <h4>{ t("SEARCH-WHERE") }</h4>
                      <Select options={ whereOptions } defaultValue={ whereSelected } isMulti onChange={selected => { setLukey("location",selected.map(t => t.value), i) }} {...defaultSelectProps } />
                    </div>
                    <div className="with">
                      <h4>{ t("SEARCH-WITH") }</h4>
                      <Select options={ withOptions } defaultValue={ withSelected } isMulti onChange={selected => { setLukey("engagement",selected.map(t => t.value), i) }} {...defaultSelectProps } />
                    </div>
                  </div>

                  <div className="space-between">
                    <div>
                      { i !== 0 && <button className="icon-button" onClick={ () => { handleLuUpClick(i) } }>↑ { t("BUILD-MOVE_UP") }</button> }
                      { i !== (data.lu.length - 1) && <button className="icon-button" onClick={ () => { handleLuDownClick(i) } } type="submit" value="down">↓ { t("BUILD-MOVE_DOWN") }</button> }
                    </div>
                    <button className="icon-button" onClick={ () => { handleLuDeleteClick(i) } } >
                      × { t("BUTTONS-DELETE") }
                    </button>
                  </div>
                </div>
              </div></CSSTransition> })
            }
          {/* </div> */}
          </TransitionGroup>
          <button className="icon-button" onClick={ () => { handleLuAddClick() } } >+ { t("BUILD-ADD_LU") }</button>
        </Row>

        { buttons }


      </form>

      { data && <CustomLs className="no-screen" data={data} terms={pageContext.terms} /> }

    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {in: [$language, "pt", "en"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default CustomLsEditor
